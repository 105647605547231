
function time(sec, fixed) {
    if (fixed === undefined) {
        fixed = 0
    }
    if(sec < 60) return sec.toFixed(fixed) + " 秒"
    if(sec < 60*60) return (sec/60).toFixed(fixed) + " 分钟"
    if(sec < 60*60*24) return (sec/60/60).toFixed(fixed) + " 小时"
    return (sec/60/60/24).toFixed(fixed) + " 天"
}

function fmtShortHumanTime(dt) {
    let y = dt.getFullYear()
    let m = (dt.getMonth() + 1 + '').padStart(2, '0')
    let d = (dt.getDate() + '').padStart(2, '0')
    let hh = (dt.getHours() + '').padStart(2, '0')
    let mm = (dt.getMinutes() + '').padStart(2, '0')
    let ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
}

// d: Date
function shortTime(d) {
    if (!d) {
        return ''
    }
    switch (typeof d) {
        case 'string':
            return fmtShortHumanTime(new Date(d))
        case 'number':
            // time by sec
            return fmtShortHumanTime(new Date(parseInt(d) * 1000))
        case 'object':
            return fmtShortHumanTime(d)
        default:
            return `${d}(Invalid type: ${typeof d})`
    }
}

// size is bytes
// fixed is toFixed num
function size(size, fixed) {
    return humanReadable(size, 1024, "B", fixed)
}

// size is bits
// fixed is toFixed num
function bits(size, fixed) {
    return humanReadable(size, 1000, "b", fixed)
}

// size is number
// fixed is toFixed num
function num(size, fixed) {
    return humanReadable(size, 1000, "", fixed)
}

// num: 数字
// base: 进制
// unit: 单位
// fixed: 保留的小数点位数
function humanReadable(num, base, unit, fixed) {
    if (fixed == undefined) {
        fixed = 2
    }

    if(isNaN(num)) return "NaN"
    if(num < base) return num + unit
    if(num < Math.pow(base, 2)) return (num/base).toFixed(fixed) + "K" + unit
    if(num < Math.pow(base, 3)) return (num/Math.pow(base, 2)).toFixed(fixed) + "M" + unit
    if(num < Math.pow(base, 4)) return (num/Math.pow(base, 3)).toFixed(fixed) + "G" + unit
    if(num < Math.pow(base, 5)) return (num/Math.pow(base, 4)).toFixed(fixed) + "T" + unit
    if(num < Math.pow(base, 6)) return (num/Math.pow(base, 5)).toFixed(fixed) + "P" + unit
    if(num < Math.pow(base, 7)) return (num/Math.pow(base, 6)).toFixed(fixed) + "E" + unit
    return (num/Math.pow(base, 7)).toFixed(fixed) + "Z" + unit
}

export default {
    time,
    shortTime,
    bits,
    num,
    size,
}