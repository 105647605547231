let watsess = require('watsess')

let watsessClient = new watsess.ApiClient()
if (watsessClient.basePath.includes("localhost")) {
    watsessClient.basePath = watsessClient.basePath.substr(watsessClient.basePath.indexOf("//localhost/") + "//localhost/".length)
}
watsessClient.defaultHeaders = {}

let release = new watsess.ReleaseApi(watsessClient)


let hialive = require('hialive')
let hialiveClient = new hialive.ApiClient()
if (hialiveClient.basePath.includes("localhost")) {
    hialiveClient.basePath = hialiveClient.basePath.substr(hialiveClient.basePath.indexOf("//localhost/") + "//localhost/".length)
}
hialiveClient.defaultHeaders = {}

let stats = new hialive.StatsApi(hialiveClient)


function errHandler(vm, error, response) {
    if (error) {
        vm.$notify.error({
            title: response.statusCode,
            message: response.text,
            duration: 0
        })
    }
}

export default {
    release,
    stats,
    errHandler,
}
