<template>
  <div>
    <div v-if="isAdmin" class="stats">
      <div class="stats-dl">
        <div>当前版本下载统计: {{downloadCount}}</div>
        &ensp;&ensp;
        <dl-stats />
      </div>

      <div class="stats-alive">
        <!-- <div>报活: {{downloadCount}}</div>
        &ensp;&ensp; -->
        <alive />
      </div>
    </div>
    <div v-show=false>{{version}}</div>
    <el-tabs v-model="selectedOS" v-if="manifest.Windows.App">
      <el-tab-pane
          v-for="(os, osName) in manifest"
          :key="osName.id"
          :label="`${osName === 'Darwin' ? 'Mac OS' : osName}${ isAdmin && os.DownloadCount ? '(' + os.DownloadCount + ')' : ''} `"
          :name="osName">

        <div class="tab-hdr">
          <div class="tab-hdr-left">
            <!-- release type: app/service -->
            <el-select v-model="selectedRelType" class="disable-select rel-type-selector">
              <div v-for="(rt, rtName) in os" :key="rtName.id">
                <el-option v-show="rt && rtName !== 'DownloadCount'" :label="rtName" :value="rtName" class="disable-select"/>
              </div>
            </el-select>

            <!-- release arch -->
            <el-select v-model="selectedArch" class="disable-select arch-selector">
              <div v-for="(arch, archName) in os[selectedRelType]" :key="archName.id">
                <el-option v-if="archName !== 'DownloadCount'" :label="archName" :value="archName" class="disable-select"/>
              </div>
            </el-select>

          </div>
          <div class="tab-hdr-right">
            {{curOS() === 'Darwin' ? 'Mac OS' : curOS()}} &ensp; | &ensp; {{curArch()}}
            {{ isAdmin && os[selectedRelType] && os[selectedRelType].DownloadCount ? (os[selectedRelType] ? '，' + selectedRelType + ' 版下载次数：' + os[selectedRelType].DownloadCount : '') : '' }}
          </div>
        </div>

        <rel-assets :assets="os[selectedRelType][selectedArch]" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Alive from './alive.vue';
import relAssets from './relAssets.vue'
import dlStats from "./downloadStats";

export default {
  name: "release",
  components: {dlStats, relAssets, Alive },
  mounted() {
    let self = this
    this.api.release.getRelease((error, data, response) => {
      if (error) {
        self.api.errHandler(self, error, response)
        return
      } else {
        console.log('API called successfully. Returned data: ', data)
        self.version = data.Version
        self.downloadCount = data.DownloadCount
        let tmpManifest = {}
        for (let o in this.manifest) {
          tmpManifest[o] = data[o]
        }
        self.manifest = self.shakeManifest(tmpManifest)
        console.log("manifest:",self.manifest)
      }
    })

  },
  data() {
    return {
      version: "0.1.0",
      downloadCount: 0,
      manifest: {
        Windows: {},
        Darwin: {},
        Linux: {},
        Docker: {},
        Harmony: {},
        Android: {},
        Iphone: {},
        Ipad: {},
        OpenWRT: {},
      },
      selectedOS: this.curOS(),
      selectedRelType: "App",
      selectedArch: this.curArch(),
    }
  },
  watch: {
    selectedOS: function() {
      // select App release type by default for any os
      if (this.selectedRelType !== 'App') this.selectedRelType = "App"

      this.selectFirstArchIfUndefined()
    },
    selectedRelType: function() {
      this.selectFirstArchIfUndefined()
    },
  },
  methods: {
    shakeManifest(manifest) {
      let shakedManifest = {}
      let validOS = []
      let foundValidAsset = false
      for (let o in manifest) {
        foundValidAsset = false
        console.log("shaking:", o)
        if (!manifest[o] || o === 'DownloadCount') continue
        for (let relType in manifest[o]) {
          if (!manifest[o][relType] || relType === 'DownloadCount') continue
          for (let arch in manifest[o][relType]) {
            if (!manifest[o][relType][arch] || arch === 'DownloadCount') continue
            for (let asset of manifest[o][relType][arch]) {
              if (asset) {
                foundValidAsset = true
                break
              }
            }
          }
        }
        if (foundValidAsset) validOS.push(o)
      }

      for (let o of validOS) {
        shakedManifest[o] = manifest[o]
        shakedManifest[o].DownloadCount = manifest[o].DownloadCount
        shakedManifest[o].App.DownloadCount = manifest[o].App.DownloadCount
        shakedManifest[o].Service.DownloadCount = manifest[o].Service.DownloadCount
      }

      return shakedManifest
    },
    selectFirstArchIfUndefined() {
      let o = this.manifest[this.selectedOS]
      let rt = o[this.selectedRelType]
      if (!rt[this.selectedArch]) {
        this.selectedArch = this.getFirstArchFromRelType(rt)
      }

      // In case no supported arch for upon selected release type, try another release type
      if (!this.selectedArch) {
        this.selectedRelType === "App" ? this.selectedRelType = "Service" : this.selectedRelType = "App"
      }
    },
    getFirstArchFromRelType(relType) {
      for (let arch in relType) {
        if (relType[arch]) return arch
      }
      return undefined
    },
    curOS() {
      let osMap = new Map
      osMap.set(this.utils.runtime.osWindows, "Windows")
      osMap.set(this.utils.runtime.osDarwin, "Darwin")
      osMap.set(this.utils.runtime.osLinux, "Linux")
      osMap.set(this.utils.runtime.osUnix, "Unix")
      osMap.set(this.utils.runtime.osAndroid, "Android")
      osMap.set(this.utils.runtime.osIpad, "Ipad")
      osMap.set(this.utils.runtime.osIpod, "Ipod")
      osMap.set(this.utils.runtime.osIpod, "Unknown")

      return osMap.get(this.utils.runtime.os)
    },
    curArch() {
      let archMap = new Map
      archMap.set(this.utils.runtime.archX64, "Amd64")
      archMap.set(this.utils.runtime.archIa32, "I386")
      archMap.set(this.utils.runtime.archArm, "Arm")
      archMap.set(this.utils.runtime.archArm64, "Arm64")
      archMap.set(this.utils.runtime.archMips, "Mips")
      archMap.set(this.utils.runtime.archMips64, "Mips64")
      archMap.set(this.utils.runtime.archRiscV64, "RiscV64")
      archMap.set(this.utils.runtime.archPPC64le, "PPC64le")
      archMap.set(this.utils.runtime.archS390x, "S390x")
      archMap.set(this.utils.runtime.archUnknown, "Unknown")

      return archMap.get(this.utils.runtime.arch)
    },
  },
}
</script>

<style scoped>
.stats {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.3em;
}
.stats-dl {
  display: flex;
  align-items: baseline;
}
.stats-alive {
  display: flex;
  align-items: baseline;
}
.tab-hdr {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.tab-hdr-right {
  color: #b1b1b1;
}
.disable-select {
  user-select: none;
}
.rel-type-selector {
  margin: 0.5em 1em 0.5em 0;
  width: 6em;
}
.arch-selector {
  margin: 0.5em 1em 0.5em 0;
  width: 6em;
}
</style>
