<template>
  <div>
    <div class="btn" @click="detailsVisible = true">
      总下载量: {{ dlStats.Count }}
    </div>

    <el-dialog :title="`历史下载统计 (总下载量: ${dlStats.Count})`" :modal="false" :visible.sync="detailsVisible">
      <div v-if="dlStats.Versions" class="details-ctn">
        <div class="ctrl-ctn">
          <div class="ctrl-left">
            <el-checkbox v-model="osDetails">系统</el-checkbox>
            <el-checkbox v-show="osDetails" v-model="typeDetails">类型</el-checkbox>
            <el-checkbox v-show="osDetails && typeDetails" v-model="archDetails">架构</el-checkbox>
          </div>
          <div class="ctrl-right">
            <el-pagination 
              background
              :current-page.sync="pageCur" 
              :page-size="10"
              :page-sizes="pageSizes"
              @size-change="pageSizeChangeHandler" 
              layout="total, sizes, prev, pager, next, jumper" 
              :total="pageTotal" />
          </div>
        </div>

        <div class="details">
          <el-table :data="pageData" style="width: 100%">
            <el-table-column sortable prop="Name" label="版本号" width="100" />
            <el-table-column sortable prop="Count" label="下载量" width="100" />
            <el-table-column prop="OSs" label="系统">
              <template slot-scope="scope">
                <stats-o-s v-if="osDetails && scope.row.OSs" :data="scope.row.OSs" :typeDetails="typeDetails" :archDetails="archDetails" />
                <div v-if="!osDetails && scope.row.OSs" class="vertical-os-ctn">
                  <div v-for="os in scope.row.OSs" :key="os.id"> {{ os.Name }}: {{os.Count ? os.Count : 0}} </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StatsOS from "./downloadStatsOS";
export default {
  name: "downloadStats",
  components: {StatsOS},
  data() {
    return {
      dlStats: {},

      detailsVisible: false,
      osDetails: false,
      typeDetails: false,
      archDetails: false,

      pageTotal: 0,
      pageSize: 10,
      pageCur: 1,
      pageSizes: [1, 2, 3, 5, 10, 20, 50, 100, 200, 500, 1000],
    }
  },
  computed: {
    pageData: function() {
      let data = []
      let start = this.pageSize * (this.pageCur - 1)
      let end = (this.pageCur) * this.pageSize
      if (end > this.pageTotal) end = this.pageTotal
      for (let i = start; i < end; i++) {
        data.push(this.dlStats.Versions[i])
      }
      return data
    },
  },
  mounted() {
    let self = this
    this.api.release.getReleaseStats((error, data, response) => {
      if (error) {
        self.api.errHandler(self, error, response)
        return
      } else {
        console.log('API called successfully. Returned data: ', data)
        self.dlStats = data
        self.pageTotal = self.dlStats.Versions.length
      }
    })
  },
  methods: {
    pageSizeChangeHandler: function(s) {
      this.pageSize = s
    } 
  },
}
</script>

<style scoped>
.btn {
  display: block;
  padding: 0 1em;
  line-height: 2em;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  cursor: pointer;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn:active {
  background-color: rgba(255, 255, 255, 0.3);
}

.details-ctn {
  height: 600px;
  /* min-height: 600px; */
  /* max-height: 800px; */
  overflow: auto;
}
.details {
  height: 550px;
  overflow: auto;
}
.ctrl-ctn {
  /* margin-left: 0.7em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vertical-os-ctn {
  display: flex;
  justify-content: space-between;
}
</style>
