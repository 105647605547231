<template>
  <div class="ctn">
    <el-tooltip class="item" effect="dark" content="历史上所有报活过的设备总数, 点击查看报活统计详情" placement="bottom-end">
    <div class="btn" @click="visible = true">
      报活: {{total}}
    </div>
    </el-tooltip>

    <el-dialog :title="`历史激活节点统计 (总下载量: ${total})`" :modal="false" :visible.sync="visible">
      <div class="dialog-ctn">
        <alive-dimension :items="osList" aliveType="os" class="table" />
        <alive-dimension :items="relTypeList" aliveType="relType" class="table" />
        <alive-dimension :items="archList" aliveType="arch" class="table" />
        <alive-dimension :items="runInList" aliveType="runIn" class="table" />
        <alive-dimension :items="versionList" aliveType="ver" class="table" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import aliveDimension from './aliveDimension.vue'
export default {
  components: { aliveDimension },
  name: "alive",
  data() {
    return {
      osList: [ "windows", "darwin", "linux", "aix", "android", "ios"], //"dragonfly", "freebsd", "illumos", "js", "netbsd", "openbsd", "plan9", "solaris"
      relTypeList: [ "App", "Service" ],
      archList: [ "amd64", "386", "arm64", "arm", "mips64", "mips", "riscv64", "ppc4le", "s390x" ],
      runInList: [ "Electron", "Docker", "Systemd", "Upstart", "BareMetal"],
      versionList: [],
      visible: false,
      total: undefined,
    }
  },
  mounted() {
    let self = this
    this.api.stats.getStatsTotal((error, data, response) => {
      if (error) {
        self.api.errHandler(self, error, response)
        return
      } else {
        console.log('getStatsTotal API called successfully. Returned data: ', data)
        self.total = data
      }
    })

    this.api.release.getReleaseStats((error, data, response) => {
      if (error) {
        self.api.errHandler(self, error, response)
        return
      } else {
        console.log('getReleaseStats API called successfully. Returned data: ', data)
        for (let v of data.Versions) {
          self.versionList.push(v.Name)
        }
      }
    })
  },
}
</script>

<style>
.btn {
  display: block;
  padding: 0 1em;
  line-height: 2em;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  cursor: pointer;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn:active {
  background-color: rgba(255, 255, 255, 0.3);
}

.table {
  margin: 2em 0;
}
</style>