const osWindows = "win32"
const osDarwin = "darwin"
const osLinux = "linux"
const osUnix = "unix"
const osAndroid = "android"
const osIphone = "iphone"
const osIpad = "ipad"
const osIpod = "ipod"
const osUnknown = "unknown"
const archX64 = "x64"
const archIa32 = "ia32"
const archArm = "arm"
const archArm64 = "arm64"
const archMips = "mips"
const archMips64 = "mips64"
const archRiscV64 = "riscv65"
const archPPC64le = "ppc64le"
const archS390x = "s390x"
const archUnknown = "unknown"
const browserEdge = "edge"
const browserSafari = "safari"
const browserChrome = "chrome"
const browserFirefox = "firefox"
const browserOpera = "opera"
const browserUnknown = "unknown"
const platformElectron = "electron"
const platformBrowser = "browser"

// getArch x64/ia32/arm/unknown
function getArch() {
    if (window.process) {
        return window.process.arch
    }

    let userAgent = window.navigator.userAgent.toLowerCase()
    // linux(deepin) (firefox/chrome) 为 x86_64
    if (userAgent.indexOf('x64') > -1 || userAgent.indexOf('x86_64')) {
        return archX64
    }

    if (userAgent.indexOf('ia32') > -1) {
        return archIa32
    }

    if (userAgent.indexOf('arm') > -1) {
        return archArm
    }

    if (getOS() === osWindows) {
        // windows 在 i386 的环境下, userAgent 不带 'x64' 字符(tested in firefox)
        return archIa32 
    }

    if (getOS() === osDarwin) {
        let w = document.createElement("canvas").getContext("webgl")
        let d = w.getExtension('WEBGL_debug_renderer_info')
        let g = d && w.getParameter(d.UNMASKED_RENDERER_WEBGL) || ""
        // definitely arm
        if (g.match(/Apple/) && !g.match(/Apple GPU/)) {
            return archArm64
        }

        // upon check doesn't work in M1 Safari
        if (getBrowser() === browserSafari) {
            return archArm64
        }
    }

    return "unknown"
}

// getBrowser edge/safari/chrome/firefox/opera/unknown
function getBrowser() {
    let userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf("edg") > -1) {
        return "edge"
    }
    if (userAgent.indexOf("safari") > -1 && userAgent.indexOf("chrome") == -1) {
        return "safari"
    }
    if (userAgent.indexOf("chrome") > -1) {
        return "chrome"
    }
    if (userAgent.indexOf("firefox") > -1) {
        return "firefox"
    }
    if (userAgent.indexOf("opera") > -1) {
        return "opera"
    }

    return "unknown"
}

// getOS win32/darwin/linux/unix/android/iphone/ipad/ipod/unknown
function getOS() {
    // check desktop platform
    let platform = window.navigator.platform.toLowerCase()
    if (platform.indexOf("win") > -1) {
        return "win32"
    }
    if (platform.indexOf("mac") > -1) {
        return "darwin"
    }

    if (platform.indexOf("linux") > -1) {
        return "linux"
    }

    // check mobile platform
    let userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf("android") > -1) {
        return "android"
    }
    if (userAgent.indexOf("iphone") > -1) {
        return "iphone"
    }
    if (userAgent.indexOf("ipad") > -1) {
        return "ipad"
    }
    if (userAgent.indexOf("ipod") > -1) {
        return "ipod"
    }


    // check others
    if (platform.indexOf("unix") > -1) {
        return "unix"
    }

    return "unknown"
}

function checkIsElectron() {
    if (!window.process || !window.process.versions || !window.process.versions['electron']) {
        return false
    }

    return true
}

function getElectronVersion() {
    if (!window.process || !window.process.versions || !window.process.versions['electron']) {
        return "unknown"
    }
    return window.process.versions['electron']
}

function checkSupportWebGL2() {
    const gl = document.createElement('canvas').getContext('webgl2')
    if (!gl) {
        return false
    }
    return true
}

function getPlatform() {
    if (checkIsElectron()) return platformElectron
    return platformBrowser
}

let os = getOS()
let arch = getArch()
let browser = getBrowser()
let platform = getPlatform()
let isElectron = checkIsElectron()
let supportWebGL2 = checkSupportWebGL2()
console.debug("os:", os)
console.debug("arch:", arch)
console.debug("browser:", browser)
console.debug("platform:", platform)
console.debug("isElectron:", isElectron)
console.debug("supportWebGL2:", supportWebGL2)


export default {
    os,
    arch,
    browser,
    platform,
    isElectron,
    supportWebGL2,
    getOS,
    getArch,
    getBrowser,
    getElectronVersion,
    osWindows,
    osDarwin,
    osLinux,
    osUnix,
    osAndroid,
    osIphone,
    osIpad,
    osIpod,
    osUnknown,
    isWindows: () => { return getOS() === osWindows ? true : false },
    isDarwin: () => { return getOS() === osDarwin ? true : false },
    isLinux: () => { return getOS() === osLinux ? true : false },
    isUnix: () => { return getOS() === osUnix ? true : false },
    isAndroid: () => { return getOS() === osAndroid ? true : false },
    isIphone: () => { return getOS() === osIphone ? true : false },
    isIpad: () => { return getOS() === osIpad ? true : false },
    isIpod: () => { return getOS() === osIpod ? true : false },
    archX64,
    archIa32,
    archArm,
    archArm64,
    archMips,
    archMips64 ,
    archRiscV64,
    archPPC64le,
    archS390x,
    archUnknown,
    browserEdge,
    browserSafari,
    browserChrome,
    browserFirefox,
    browserOpera,
    browserUnknown,
    getPlatform,
    platformElectron,
    platformBrowser,
}
