<template>
  <div>
    <el-table :data="data" style="width: 100%">
      <el-table-column prop="Name" label="系统名" width="100" />
      <el-table-column sortable prop="Count" label="下载量" width="100" />
      <el-table-column prop="Architectures" label="CPU 架构">
        <template v-if="archDetails" slot-scope="scope">
          <el-table v-if="scope.row.Architectures" :data="scope.row.Architectures" style="width: 100%">
            <el-table-column prop="Name" label="系统名" width="100" />
            <el-table-column sortable prop="Count" label="下载量" width="100" />
          </el-table>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "downloadStatsType",
  props: ["data", "archDetails"]
}
</script>

<style scoped>

</style>
