<template>
  <div>
    <div class="hdr">{{title}}</div>
    <div class="data-ctn">
      <div class="desc-ctn">
        <div class="desc">Today</div>
        <div class="desc">Total</div>
      </div>
      <div class="tbl-ctn">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column v-for="item in items" :key="item.id" :prop="item" :label="item" width="100" />
        </el-table>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "aliveDimension",
  props: ["items", "aliveType"],
  data() {
    return {
      title: "",
      tableData: [],
    }
  },
  beforeMount() {
    let today = {}
    let total = {}
    
    for (let item of this.items) {
      today[item] = 0
      total[item] = 0
    }
    this.tableData.push(today)
    this.tableData.push(total)

    console.log("this.tableData:", this.tableData)
  },
  mounted() {
    switch (this.aliveType) {
      case "os":
        this.updateOS()
        this.title = "按操作系统统计"
        break
      case "relType":
        this.updateRelType()
        this.title = "按运行方式统计"
        break
      case "arch":
        this.updateArch()
        this.title = "按处理器架构统计"
        break
      case "runIn":
        this.updateRunIn()
        this.title = "按运行环境统计"
        break
      case "ver":
        this.updateVer()
        this.title = "按版本统计"
        break
    }
  },
  methods: {
    updateOS: function() {
      let self = this
      for (let item of this.items) {
        this.api.stats.getStatsOS(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get ${item} count: `, data)
            self.tableData[1][item] = data
          }
        })
      }
      for (let item of this.items) {
        this.api.stats.getStatsTodayOS(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get today ${item} count: `, data)
            self.tableData[0][item] = data
          }
        })
      }
    },
    updateRelType: function() {
      let self = this
      for (let item of this.items) {
        this.api.stats.getStatsRelType(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            console.log(`Get ${item} count: `, data)
            self.tableData[1][item] = data
          }
        })
      }
      for (let item of this.items) {
        this.api.stats.getStatsTodayRelType(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            console.log(`Get today ${item} count: `, data)
            self.tableData[0][item] = data
          }
        })
      }
    },
    updateArch: function() {
      let self = this
      for (let item of this.items) {
        this.api.stats.getStatsArch(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get ${item} count: `, data)
            self.tableData[1][item] = data
          }
        })
      }
      for (let item of this.items) {
        this.api.stats.getStatsTodayArch(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get today ${item} count: `, data)
            self.tableData[0][item] = data
          }
        })
      }
    },
    updateRunIn: function() {
      let self = this
      for (let item of this.items) {
        this.api.stats.getStatsRunIn(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get ${item} count: `, data)
            self.tableData[1][item] = data
          }
        })
      }
      for (let item of this.items) {
        this.api.stats.getStatsTodayRunIn(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get today ${item} count: `, data)
            self.tableData[0][item] = data
          }
        })
      }
    },
    updateVer: function() {
      let self = this
      for (let item of this.items) {
        this.api.stats.getStatsVer(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            console.log(`Get ${item} count: `, data)
            self.tableData[1][item] = data
          }
        })
      }
      for (let item of this.items) {
        this.api.stats.getStatsTodayVer(item, (error, data, response) => {
          if (error) {
            self.api.errHandler(self, error, response)
            return
          } else {
            // console.log(`Get today ${item} count: `, data)
            self.tableData[0][item] = data
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.hdr {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
}

.data-ctn {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2em;
}
.desc-ctn {
  line-height: 3.6em;
  width: 4em;
  min-width: 4em;
  max-width: 4em;
}
.tbl-ctn {
  width: 100%;
  overflow: auto;
}
</style>