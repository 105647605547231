import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import './assets/el-theme/dark/theme/index.css'
import 'vue-material/dist/theme/black-green-dark.css'
import FlatSurfaceShader from 'vue-flat-surface-shader'
import api from './api'
import utils from './utils'
import './assets/iconfont.js'
import icon from './components/common/icon/icon.vue'


Vue.use(ElementUI)
Vue.use(FlatSurfaceShader)
Vue.config.productionTip = false
Vue.prototype.api = api
Vue.prototype.utils = utils
Vue.prototype.isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
Vue.component('icon', icon)

new Vue({
  render: h => h(App),
}).$mount('#app')
