<template>
    <div class="ctn">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ os }}</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">下载</el-button> -->
                <icon style="float: right; font-size: 20px;" type="text">{{ icon }}</icon>
            </div>
            <div v-for="o in list" :key="o" class="text item">
                {{ o }}
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "platform",
    props: {
        os: String,
        list: Array,
        icon: String,
    }
}
</script>

<style scoped>
.ctn {
    margin: 0.2em;
}
.box-card {
    width: 320px;
    height: 200px;
    background-color:rgba(255, 255, 255, 0.1);
}
</style>