<template>
    <svg class="icon" aria-hidden="true">
        <use :xlink:href="iconName"></use>
        <slot></slot>
    </svg>
</template>

<script>
export default {
    name: 'icon',
    computed: {
        iconName: function() {
            return "#icon-" +  this.$slots.default[0].text;
        }
    },
}
</script>

<style scoped>
.icon {
    width: 1em; height: 1em;
    fill: currentColor;
    overflow: hidden;
 }
</style>
