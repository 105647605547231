<template>
    <div class="ctn">
        <platform class="platform" os="Windows 7/8/10/11" icon="windows2" :list="['桌面版: x64', '服务版: ia32, x64']" />
        <platform class="platform" os="Mac OS" icon="macos" :list="['桌面版: x64, arm64', '服务版: x64, arm64']" />
        <platform class="platform" os="Linux" icon="linux" :list="['桌面版: ia32, x64, arm, arm64', '服务版: ia32, x64, arm, arm64, riscv64, ppc64le, s390x, mips, mips64']" />
        <platform class="platform" os="Docker" icon="docker" :list="['服务版: ia32, x64, arm, arm64, ppc64le, s390x']" />
        <!-- <platform class="platform" os="鸿蒙" :list="['移动版: Harmony 2.0+']" />
        <platform class="platform" os="Android" icon="android" :list="['移动版: Android 4.0+']" />
        <platform class="platform" os="iOS" icon="macos" :list="['移动版: iOS 5.0+']" />
        <platform class="platform" os="OpenWRT" :list="['路由器版: 26+']" /> -->
    </div>
</template>

<script>
import platform from './platform.vue'
export default {
  components: { platform },
    name: "platforms"
}
</script>

<style scoped>
.ctn {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
}
</style>