<template>
  <div class="app-ctn">
    <flat-surface-shader v-if="enableAnimation" class="shader" type="webgl" :light=lightOption :mesh=meshOption />
    <div class="ctn">
      <div class="hdr">
        <div class="hdr-left">
          <img src="@/assets/images/logo.png" alt="waterfall session logo" width="40px" class="hdr-logo">
          <div>Waterfall Session</div>
        </div>
        <div class="hdr-right">
          <div class="hdr-btn" @click="contactVisible = true">联系我们</div>
          <!-- <div class="hdr-btn">文档</div> -->
          <el-tooltip class="item" effect="dark" :content="enableAnimation ? '关闭背景动画' : '显示背景动画'" placement="bottom-end">
            <el-switch class="hdr-switch" v-model="enableAnimation" />
          </el-tooltip>
        </div>
      </div>
      <div class="section1">
        <div class="section1-left" v-if="true">
          <slogan />
          <features></features>
        </div>
        <div class="section1-right">
          <release />
        </div>
      </div>
    </div>

    <el-dialog
      title="联系方式"
      width="500px"
      :visible.sync="contactVisible">
      <span>邮箱: watsess@163.com</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="contactVisible = false" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Release from "./components/release/release";
import slogan from "./components/slogan/slogan";
import features from "./components/description/features";

export default {
  name: 'App',
  components: {slogan, Release, features},
  data() {
    let background = "#1e1e1e"
    let black = '#1e1e1e'
    return {
      enableAnimation: true,
      lightOption: {
        // default: 2, 设置光量，数值越大越亮
        count: 1.5,
        // default: 1， x/y 轴的比例
        xyScalar: 1,
        // default: 100
        zOffset: 80,
        // default: 0.001
        speed: 0.001,
        gravity: 1200, // default:
        // default: 0.95, 抑制
        dampening: 0.95,
        minLimit: 10, // default:
        maxLimit: null, // default:
        minDistance: 20, // default:
        maxDistance: 400, // default:
        // default: false, 光点是否自动移动， 设置为 false 则跟随鼠标移动
        autopilot: true,
        // default: '#22bc9e'，氛围
        ambient: background,
        // default: '#2b7c6b'，弥漫性
        diffuse: black,
        draw: false // default:
      },
      meshOption: {
        // default: 1.2, 整个动画的宽度所占容器比例
        width: 1.2,
        // default: 1.2, 整个动画的高度所占容器比例
        height: 1.2,
        // default: 10, 深度越大， 黑白越明显
        depth: 10,
        // default: 16，裂片数量，纵向切片数量
        segments: 28,
        // default: 8，切片数量，数值越大，横向切片数量
        slices: 14,
        // default: 0.8, 数值越大，x 轴越长
        xRange: 0.8,
        // default: 0.1, 数值越大，y 轴越长
        yRange: 0.3,
        // default: 1.0, 数值越大，z 轴越长
        zRange: 0.8,
        // default: '#555555'，氛围
        ambient: '#555555',
        // default: '#FFFFFF'，弥漫性
        diffuse: '#FFFFFF',
        // default: 0.002, 数值越大，切块运动越快
        speed: 0.0002,
      },
      contactVisible: false,
    }
  },
  mounted() {
    let stored = localStorage.getItem('enableAnimation')
    if (stored) {
      this.enableAnimation = JSON.parse(stored)
    }
  },
  watch: {
    enableAnimation: function() {
      localStorage.setItem('enableAnimation', JSON.stringify(this.enableAnimation))
    }
  },
}
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
html, body {
  margin: 0;
  padding: 0;
  background: #1e1e1e;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
    background:#d3d3d3;
    color:#555;
}

::-moz-selection {
    background:#d3d3d3;
    color:#555;
}

::-webkit-selection {
    background:#d3d3d3;
    color:#555;
}
</style>

<style scoped>
.app-ctn {
  height: 100vh;
  position: relative;
}
.shader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
}
.ctn {
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  left: 0px;
  top: 0px;
  color: white;
}
.hdr {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.05);

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hdr-left {
  margin-left: 3em;
  display: flex;
  align-items: center;
}
.hdr-right  {
  margin-right: 1em;
  display: flex;
  align-items: center;
}
.hdr-logo {
  padding-top: 4px;
  margin-right: 1em;
}
.hdr-btn {
  padding: 0 2em;
  margin-left: 1em;
  line-height: 40px;
  border-radius: 3px;
  user-select: none;
  cursor: default;
}
.hdr-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.hdr-btn:active {
  background-color: rgba(255, 255, 255, 0.2);
}
.hdr-switch {
  padding: 0 2em;
  margin-left: 1em;
}
.section1 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 168px;
}

.section1-right {
  width: 45%;
  margin-left: 6%;
}
</style>

<style>
/*设置 chrome/safari 下的滚动条样式*/
 ::-webkit-scrollbar {
   width: 10px;
   height: 10px;
   background: transparent;
 }
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #666;
  transition: all .2s;
  border-radius: 5px;
}
</style>