<template>
   <!-- :class="{layout1: assets && assets.length === 1, layout2: assets && assets.length === 2}" -->
  <div class="ctn asset-layout">
    <div class="asset" v-for="asset in sortedAssets" :key="asset.id">
      <!-- asset info -->
      <div class="info">
        {{asset.Info}}
      </div>

      <!-- asset download/size stat -->
      <div class="stats">
        <div>发布日期: {{ utils.human.shortTime(asset.RelAt) }}</div>
        <div v-if="isAdmin">下载次数: {{asset.DownloadCount}}</div>
        <div v-if="asset.Size">文件大小: {{ utils.human.size(asset.Size, 0) }}</div>
      </div>

      <!-- asset download button -->
      <a v-if="asset.URL" class="downloadBtn" :class="{'asset-recommended-bg': asset.Recommended}" :href="asset.URL" >
        {{asset.Name}}
      </a>

      <!-- asset one key installer command -->
      <div v-if="asset.OneKeyInstaller" class="one-key-installer">
        <div class="one-key-installer-cmd">{{asset.OneKeyInstaller}}</div>
        <div class="el-icon-copy-document one-key-installer-btn" @click="copyCmd(asset.OneKeyInstaller)" />
      </div>

      <!-- asset release note -->
      <div class="note">
        <div v-for="note in asset.Note" :key="note.id">{{note}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "relAsset",
  props: ["assets"],
  data() {
    return {
      sortedAssets: [],
    }
  },
  mounted() {
    this.sortedAssets = this.sortAssets(this.assets)
  },
  watch: {
    assets: function() {
      this.sortedAssets = this.sortAssets(this.assets)
    },
  },
  methods: {
    sortAssets: function(assets) {
      if (!assets) return []
      let sortedAssets = []
      for (let asset of assets) {
        asset.Recommended ? sortedAssets.unshift(asset) : sortedAssets.push(asset)
      }
      console.log("sortedAssets:", sortedAssets)
      return sortedAssets
    },
    copyCmd: function(cmd) {
      this.utils.dom.copyToClipboard(cmd)
      this.$message.success("已复制到粘贴板")
    }
  },
}
</script>

<style scoped>
.ctn {
  margin: 1em 0;
  color: #d6d6d6;
}
.layout1 {
  width: 100%;
}
.layout2 {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}
.asset-layout {
  display: flex;
  justify-content: space-between;
}
.asset {
  width: 100%;
  background-color:rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 1em;
  margin: 1em 0.2em;
}
.info {
  font-size: 0.8em;
  margin: 1em 0 3em 0;
}
.stats {
  color: #b1b1b1;
  font-size: 0.7em;
  line-height: 2.5em;
  display: flex;
  justify-content: space-between;
}

a {
  color: #ffffff;
  text-decoration-line: none;
}
a:visited {
  color: #ffffff;
}
.downloadBtn {
  display: block;
  width: 100%;
  line-height: 3em;
  font-size: 1.1em;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.downloadBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.downloadBtn:active {
  background-color: rgba(255, 255, 255, 0.5);
}
.asset-recommended-bg {
  background-color:rgba(255, 255, 255, 0.5);
}
.asset-recommended-bg:hover {
  background-color:rgba(255, 255, 255, 0.6);
}
.asset-recommended-bg:active {
  background-color:rgba(255, 255, 255, 0.7);
}
.one-key-installer {
  padding: 0.6em 1em;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.2);

  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.one-key-installer-cmd {
  line-height: 1.8em;
}
.one-key-installer-btn {
  padding: 0.5em;
  border-radius: 3px;
}
.one-key-installer-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.one-key-installer-btn:active {
  background-color: rgba(255, 255, 255, 0.5);
}
/*.rel-date {*/
/*  color: #b1b1b1;*/
/*  font-size: 0.7em;*/
/*  line-height: 2.5em;*/
/*  margin-bottom: 2em;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/
.note {
  margin-top: 3em;
  color: #b1b1b1;
  font-size: 0.8em;
  line-height: 2em;
}
</style>
