<template>
  <div class="slogan">
    <!-- <div class="logo">
      <img src="@/assets/images/logo.png" alt="waterfall session logo" width="333px">
    </div> -->
    <div class="text">
      <!-- <div class="text-primary">
        <div>用命令行的人有两种</div>
        <div>一种是喜欢命令行的，另一种是讨厌命令行的</div>
      </div>
      <div class="text-line">
        <div>喜欢命令行的人在这里可以通过键盘完成所有日常操作</div>
        <div>讨厌命令行的人在这里可以通过鼠标完成所有日常操作</div>
        <div>如果你喜欢命令行，推荐你试试, 如果你讨厌命令行，强烈推荐你试试! 试试又不会逝世！</div>
      </div> -->

      <div class="text-primary">
        <div>Just Another Terminal App.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slogan"
}
</script>

<style scoped>
.slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.logo {
  margin-bottom: 2em;
}
.text-primary {
  font-size: 1.6em;
  line-height: 2.1em;
  margin: 1em;
}
.text {
  font-size: 1em;
  line-height: 2.1em;
}
.text-line {
  color: #d6d6d6;
}
</style>
