<template>
  <div>
    <el-table :data="data" style="width: 100%">
      <el-table-column prop="Name" label="系统名" width="100" />
      <el-table-column sortable prop="Count" label="下载量" width="100" />
      <el-table-column v-if="typeDetails" prop="Types" label="应用类别">
        <template slot-scope="scope">
          <stats-type :data="scope.row.Types" :archDetails="archDetails" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import StatsType from "./downloadStatsType";
export default {
  name: "downloadStatsOS",
  components: {StatsType},
  props: ["data", "typeDetails", "archDetails"],
}
</script>

<style scoped>

</style>
