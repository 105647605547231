<template>
  <div class="slogan">
    <div class="text">
      <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <platforms />
      </div>
    </div>
  </div>
</template>

<script>
import Platforms from './platforms.vue'

export default {
  name: "features",
  components: {
    Platforms,
  },
}
</script>

<style scoped>
.text {
  font-size: 1em;
  line-height: 2.1em;
}
</style>
  