// return a promise
// usage:
// copyToClipboard("copyText")
//     .then(() => console.log('text copied !'))
//     .catch(() => console.log('error'));
function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

// bug: 如果 data 中包含 '#' 字符(不包含单引号), 则从 '#' 字符开始, '#' 字符及其后面的内容都不会下载
function downloadText(name, data) {
    var e = document.createElement('a')
    console.log("downloadText: data:", data)
    e.setAttribute('href', 'data:text/plain;charset=utf-8,' + data)
    e.setAttribute('download', name)
    e.style.display = 'none'
    document.body.appendChild(e)
    e.click()
    document.body.removeChild(e)
}

function downloadBlob(name, data) {
    let e = document.createElement('a')
    let blob = new Blob([data])
    e.href = URL.createObjectURL(blob);
    e.setAttribute('download', name)
    e.style.display = 'none'
    document.body.appendChild(e)
    e.click()
    document.body.removeChild(e)
}

function printText(text) {
    let iframe = document.createElement('iframe')
    let body = document.createElement('body')
    let pre = document.createElement('pre')
    pre.innerHTML = text
    body.appendChild(pre)
    iframe.srcdoc = body.innerHTML
    document.body.appendChild(iframe)
    iframe.contentWindow.print()
    setTimeout(() => {document.body.removeChild(iframe)}, 1000)
}

// 通过 setTimeout focus 到指定 id 的元素上
// id: dom 元素 id，字符串
// delay：延迟时间，单位毫秒，默认 100ms
function domFocusById(id, delay) {
    setTimeout(() => {
        document.getElementById(id).focus()
    }, delay ? delay : 100)
}

// 浏览器环境下, 在关闭浏览器标签或刷新前弹窗确认是否要关闭
// electron 环境下, 这里的内容不会显示
// 但必须定义 onbeforeunload 事件且返回值不为 null 或 undefined
// electron 中的 'close' 事件才会触发
function enableCloseConfirm() {
    window.onbeforeunload = function(e) {
        console.log("onbeforeunload handler")
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '确认关闭'
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return '确认关闭'
    }
}

function disableCloseConfirm() {
    window.onbeforeunload = undefined
}

function toggleFullScreen(selector) {
    let el = document
    if (selector) {
        el = document.querySelector(selector)
    }
    
    if (!el.fullscreenElement) {
        el.documentElement.requestFullscreen();
    } else {
        if (el.exitFullscreen) {
            el.exitFullscreen();
        }
    }
}

export default {
    copyToClipboard,
    downloadText,
    downloadBlob,
    printText,
    domFocusById,
    enableCloseConfirm,
    disableCloseConfirm,
    toggleFullScreen,
}
